<template>
  <article>
    <Hero heading="Introducing the NACS/J3400 Pilot Charging Stations">
      <template slot="image">
        <picture>
          <img src="@/assets/images/NACSPilot/hero.png" alt="Plugging in an electric vehicle charging connector" />
        </picture>
      </template>
    </Hero>

    <section class="l-one bg-gray">
      <div class="l-one__col l-one__col--wide text-center">
        <h2 class="headline3">Meeting the Needs of the EV Community</h2>
        <p>
          The electric vehicle (EV) industry is evolving, and we’re ready to keep pace. Available in Orlando, FL and
          Waterford, CT, our pilot stations featuring both NACS/J3400 and CCS connectors provide Hyper-Fast charging
          speeds.
        </p>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="column pilot-station">
          <div>
            <div class="header">
              <img src="@/assets/images/NACSPilot/charger-icon.png" alt="Charger Icon" class="charger-icon" />
              <h3>
                Pilot Station<br />
                Locations:
              </h3>
            </div>

            <div class="location">
              <img src="@/assets/images/NACSPilot/location-pin.png" alt="Location Pin" class="pin-icon" />
              <div>
                <strong>Waterford Commons (Station ID 100286):</strong><br />
                <span class="address">915 Hartford Turnpike, Waterford, CT 06385</span>
              </div>
            </div>

            <div class="location">
              <img src="@/assets/images/NACSPilot/location-pin.png" alt="Location Pin" class="pin-icon" />
              <div>
                <strong>The Florida Mall (Station ID 100255):</strong><br />
                <span class="address">8001 S Orange Blossom Trl, Orlando, FL 32809</span>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <img
            src="@/assets/images/NACSPilot/map.png"
            alt="Map of the United States with a pin on the listed stations"
            class="map-image"
          />
        </div>
      </div>
    </section>

    <section class="l-one">
      <div class="l-one__col l-one__col--wide">
        <h2 class="headline3">Frequently Asked Questions (FAQs)</h2>
        <p>
          Get answers to some of the most commonly asked questions about our pilot stations featuring both CCS and
          NACS/J3400 connectors.
        </p>
        <accordion>
          <accordion-item title="Where can I find Electrify America pilot stations?">
            <p>
              These pilot stations featuring our new station layout with both CCS and NACS/J3400 connectors are located
              at:
            </p>
            <ul>
              <li>The Florida Mall in Orlando, FL (Station ID 100255)</li>
              <li>Waterford Commons in Waterford, CT (Station ID 100286)</li>
            </ul>
            <p>
              For a full list of all Electrify America locations, visit the
              <router-link :to="{ name: 'mobile-app-en' }" class="link">Electrify America app</router-link> or use our
              <router-link :to="{ name: 'locate-charger-en' }" class="link">Locate a Charger</router-link> feature.
            </p>
          </accordion-item>
          <accordion-item
            title="What is the purpose of these pilot stations, and how is the charging experience
different?"
          >
            <p>
              We’re always looking for ways to learn and innovate, and these pilot stations allow us to test a new
              station layout with both CCS and NACS/J3400 connectors and gather information from those who try them out.
            </p>
            <p>
              These chargers can also charge two vehicles at the same time on one charger, allowing us to simultaneously
              power two EV journeys. If you stop by, make sure to let us know your thoughts!
            </p>
          </accordion-item>
          <accordion-item title="Can I use my Electrify America app to charge at these stations?">
            <p>
              Yes! The Electrify America app can be used to start and manage your charging session, just like at any
              other Electrify America location.
            </p>
          </accordion-item>
        </accordion>
      </div>
    </section>
  </article>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion.vue';
import AccordionItem from '@/components/Accordion/AccordionItem.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'getting-started',
  components: { Accordion, AccordionItem, Hero },
  metaInfo: {
    title: `NACS Pilot Stations | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `Available in Orlando, FL and Waterford, CT, our pilot stations featuring both NACS/J3400 and CCS connectors provide Hyper-Fast charging speeds`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/pilot-stations/' }],
  },
};
</script>

<style lang="scss" scoped>
.bg-gray {
  background: $c-secondary-background;
}
.l-one {
  padding-top: 80px;
  padding-bottom: 80px;

  .text-center {
    text-align: center;
  }
}

.headline3 {
  padding-bottom: 16px;
}

.container {
  display: flex;
  padding-top: 100px;
  @media (min-width: 1000px) {
    flex-direction: row;
    padding-left: 8%;
    padding-right: 8%;

    .column {
      width: 50%;
    }
  }
  padding-left: 4%;
  padding-right: 4%;
  flex-direction: column-reverse;
}

.pilot-station {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.charger-icon {
  width: 150px;
  padding-right: 4%;
}

.location {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.pin-icon {
  width: 25px;
  margin-right: 10px;
  margin-top: 5px;
}

.map-image {
  max-width: 100%;
}
</style>
